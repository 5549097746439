<!--
 * @Author: snltty
 * @Date: 2021-10-11 09:30:14
 * @LastEditors: snltty
 * @LastEditTime: 2021-10-11 09:55:06
 * @version: v1.0.0
 * @Descripttion: 功能说明
 * @FilePath: \pc\src\App.vue
-->
<template>
    <CommonHeader></CommonHeader>
    <CarHeader></CarHeader>
    <router-view />
</template>

<script>
import CommonHeader from './components/CommonHeader.vue'
import CarHeader from './components/CarHeader.vue'
export default {
    components: { CommonHeader, CarHeader },
    name: 'Home',
}
</script>
<style lang="stylus"></style>
