<!--
 * @Author: snltty
 * @Date: 2021-10-11 09:35:54
 * @LastEditors: snltty
 * @LastEditTime: 2021-10-12 11:37:04
 * @version: v1.0.0
 * @Descripttion: 功能说明
 * @FilePath: \pc\src\components\CarHeader.vue
-->
<template>
    <header>
        <div class="car-header flex">
            <div class="logo flex"><img src="../assets/logo.svg" alt=""><span>智能积木|智能机器人</span></div>
            <span class="flex-1"></span>
            <div class="menu flex">
                <router-link :to="{name:'Home'}">首页</router-link>
                <router-link :to="{name:'Product'}">核心产品</router-link>
                <router-link :to="{name:'Course'}">课程设计</router-link>
                <router-link :to="{name:'News'}">动态资讯</router-link>
            </div>
            <div class="menu-2 flex">
                <el-dropdown>
                    <div>
                        <el-icon :size="20">
                            <grid />
                        </el-icon>
                    </div>
                    <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item>
                                <router-link :to="{name:'Home'}">首页</router-link>
                            </el-dropdown-item>
                            <el-dropdown-item>
                                <router-link :to="{name:'Product'}">核心产品</router-link>
                            </el-dropdown-item>
                            <el-dropdown-item>
                                <router-link :to="{name:'Course'}">课程设计</router-link>
                            </el-dropdown-item>
                            <el-dropdown-item>
                                <router-link :to="{name:'News'}">动态资讯</router-link>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </div>
            <span class="flex-1"></span>
            <div class="phone">
                <el-button type="success" @click="showAdd = true">引进课程</el-button>
            </div>
        </div>
    </header>
    <el-dialog title="引进课程留言" v-model="showAdd" center :close-on-click-modal="false" width="50rem">
        <el-form ref="formDom" :model="form" :rules="rules" label-width="8rem">
            <el-form-item label="手机号码" prop="phone">
                <el-input v-model="form.phone"></el-input>
            </el-form-item>
            <el-form-item label="验证码" prop="code">
                <div class="flex">
                    <div class="flex-1">
                        <el-input v-model="form.code"></el-input>
                    </div>
                    <span class="split"></span>
                    <el-button @click="handleCode" :loading="loading">发送验证码</el-button>
                </div>
            </el-form-item>
            <el-form-item label="您的称呼" prop="name">
                <el-input v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item label="留言内容" prop="msg">
                <el-input v-model="form.msg" type="textarea"></el-input>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button @click="showAdd = false">取 消</el-button>
            <el-button type="primary" :loading="loading" @click="handleSubmit">确 定</el-button>
        </template>
    </el-dialog>
</template>

<script>
import { reactive, ref, toRefs } from '@vue/reactivity'
import axios from 'axios'
import { ElMessage } from 'element-plus'
export default {
    setup () {

        const state = reactive({
            form: {
                phone: '',
                code: '',
                name: '',
                msg: ''
            },
            rules: {
                phone: [
                    { required: true, message: '必填', trigger: 'blur' },
                ],
                code: [
                    { required: true, message: '必填', trigger: 'blur' },
                ]
            },
            loading: false,
            showAdd: false
        });

        const handleCode = () => {
            if (!state.form.phone) {
                return ElMessage.error('请填写手机号码');
            }
            state.loading = true;
            axios.post(`https://api.qbcode.cn:8081/api/robotcar/login/PhoneCode?phone=${state.form.phone}`).then((res) => {
                state.loading = false;
                if (res.data.Data == true) {
                    ElMessage.success('已发送');
                } else {
                    ElMessage.error(res.data.Message);
                }
            });
        }

        const formDom = ref(null);
        const handleSubmit = () => {
            formDom.value.validate((valid) => {
                if (!valid) {
                    return false;
                }

                const form = JSON.parse(JSON.stringify(state.form));
                state.loading = true;

                axios.post(`https://api.qbcode.cn:8081/api/robotcar/login/msg`, form).then((res) => {
                    state.loading = false;
                    if (res.data.State == 'Success') {
                        ElMessage.success('留言成功');
                        state.showAdd = false;
                    } else {
                        ElMessage.error(res.data.Message);
                    }
                });
            })
        }

        return {
            ...toRefs(state), formDom, handleCode, handleSubmit
        }
    }
}
</script>

<style lang="stylus" scoped>
@media screen and (max-width: 768px)
    .menu
        display: none;

    .menu-2
        display: block !important;
        line-height: normal !important;

        .el-icon
            margin-top: 0.8rem;

    .logo
        img
            display: block !important;
            vertical-align: middle;
            margin-top: 0.4rem;

header
    border-bottom: 1px solid #ddd;
    padding: 1rem 1rem;

.car-header
    width: 100%;
    max-width: 131.2rem;
    margin: 0 auto;
    line-height: 4rem;

    .logo
        font-size: 1.6rem;

        img
            display: none;
            height: 3rem;
            margin-right: 0.6rem;

    .menu-2
        display: none;
        line-height: 4rem;

    .menu
        a
            font-size: 1.4rem;
            line-height: 4rem;
            display: block;
            padding: 0 2rem;
            color: #333;

            &.router-link-active, &:hover
                color: green;
                font-weight: bold;
</style>