<!--
 * @Author: snltty
 * @Date: 2021-10-11 09:35:54
 * @LastEditors: snltty
 * @LastEditTime: 2021-10-11 14:45:41
 * @version: v1.0.0
 * @Descripttion: 功能说明
 * @FilePath: \pc\src\components\CommonHeader.vue
-->
<template>
    <header>
        <div class="common-header flex">
            <div class="logo">
                <img src="../assets/logo.svg" alt="">
            </div>
            <span class="flex-1"></span>
            <div class="menu flex">
                <a href="https://www.qbcode.cn/" target="_blank">奇贝官网</a>
                <a href="https://hour.qbcode.cn/express/#/" target="_blank">体验课程</a>
                <a href="https://hour.qbcode.cn/python/#/" target="_blank">通关课程</a>
                <a href="https://s3.qbcode.cn/" target="_blank">积木编程</a>
                <a href="https://www.qbcode.cn/register.html" target="_blank">预约线下课程</a>
            </div>
            <span class="flex-1"></span>
            <div class="phone">
                <el-icon>
                    <phone />
                </el-icon> 18078221448
            </div>
        </div>
    </header>
</template>

<script>
export default {
    setup () {
        return {}
    }
}
</script>

<style lang="stylus" scoped>
@media screen and (max-width: 768px)
    .menu
        display: none;

    header
        display: none;

header
    border-bottom: 1px solid #eee;
    padding: 1rem 1rem;

.common-header
    width: 100%;
    max-width: 131.2rem;
    margin: 0 auto;
    line-height: 5rem;

    .logo img
        height: 5rem;
        vertical-align: top;

    .menu
        a
            font-size: 1.6rem;
            display: block;
            padding: 0 2rem;
            color: #333;
            font-weight: bold;

            &:hover
                color: green;

    .phone
        .el-icon
            vertical-align: sub;
</style>