/*
 * @Author: snltty
 * @Date: 2021-10-11 09:30:14
 * @LastEditors: snltty
 * @LastEditTime: 2021-10-12 10:49:17
 * @version: v1.0.0
 * @Descripttion: 功能说明
 * @FilePath: \pc\src\router\index.js
 */
import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('../views/Home.vue')
    },
    {
        path: '/product.html',
        name: 'Product',
        component: () => import('../views/Product.vue')
    },
    {
        path: '/course.html',
        name: 'Course',
        component: () => import('../views/Course.vue')
    },
    {
        path: '/news.html',
        name: 'News',
        component: () => import('../views/News.vue')
    },
    {
        path: '/new.html',
        name: 'New',
        component: () => import('../views/New.vue')
    },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router
