/*
 * @Author: snltty
 * @Date: 2021-10-11 09:30:14
 * @LastEditors: snltty
 * @LastEditTime: 2021-10-11 14:49:36
 * @version: v1.0.0
 * @Descripttion: 功能说明
 * @FilePath: \pc\src\main.js
 */
import { createApp } from 'vue'
import App from './App.vue'
const app = createApp(App);

import './assets/clear.css'

import router from './router'
app.use(router);

import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css'
app.use(ElementPlus);

import { Phone, Grid, ArrowDown } from '@element-plus/icons'
app.component(Phone.name, Phone);
app.component(Grid.name, Grid);
app.component(ArrowDown.name, ArrowDown);

app.mount('#app');
